<template>
  <div class="flex-c bread pl20">
    <el-breadcrumb separator="/" class="mr20">
      <el-breadcrumb-item v-for="(item, index) in list" :key="index" :to="item.to" @click.native="to(item)">
        {{ item.title }}
      </el-breadcrumb-item>
      <el-breadcrumb-item v-for="(item, index) in $route.meta.breadList" :key="index + 5">{{ item.name }}</el-breadcrumb-item>
    </el-breadcrumb>
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    list: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {}
  },
  methods: {
    to(item) {
      if(!item.isDialog) {
        return
      }
      this.$emit('to')
    }
  }
}
</script>
<style lang="scss" scoped>
.bread {
  background: $bg-tip-color;
  height: 50px;
  overflow: hidden;
}
</style>

<!--  -->
<template>
  <div class="flex h100">
    <div class="flex-1 h100" style="width: 0">
      <common-bread :list="breadcrumbData" />
      <keep-alive>
        <router-view style="height: calc(100% - 55px)"></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import bus from '@/utils/bus'
import { mapGetters } from 'vuex'
import CommonBread from '@/components/commonBread'
export default {
  data() {
    return {
      breadcrumbData: [
        { to: { path: '/devops/engineList', query: {} }, title: '首页' },
        { to: { path: `/devops/engineList`, query: {} }, title: '页面引擎' },
        {
          to: { path: `/devops/engineList`, query: {} },
          title: this.$route.query.engineName,
        },
        {
          to: {
            path: '/devops/engineList',
            query: {},
          },
          title: this.$route.query.engineVersion,
        },
        {
          title: '引擎配置',
        },
      ],
    }
  },
  components: { CommonBread },

  computed: {
    ...mapGetters(['isFoldSlider']),
  },
  created() {
    bus.$on('showSpread', (bol) => {
      this.showField = bol
    })
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.config-dev-main {
  background: white;
  height: calc(100% - 35px);
}
</style>
